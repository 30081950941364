import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Flashcard } from "../components/flashcard/Flashcard";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  Typography,
} from "@mui/joy";
import {
  ArrowBackIos as ArrowBack,
  ArrowForwardIos as ArrowForward,
} from "@mui/icons-material";
import { Header } from "../components/Header";
import { getIsFlipped, setFlipCard } from "../redux/features/card";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  fetchCategoryById,
  selectCategoryById,
} from "../redux/features/categories";
import { RootState } from "../redux/store";
import { getCategoryAnswers } from "../redux/features/answers";
import { PDFDownloadLink } from "@react-pdf/renderer";
import OutputPDF from "../components/pdf/OutputDocument";

export const Flashcards = () => {
  const { id } = useParams<{ id: string }>();

  const [currentFlashcard, setCurrentFlashcard] = useState(0);

  const cardFlipped = useAppSelector(getIsFlipped);
  const dispatch = useAppDispatch();
  const categoryAnswers = useAppSelector((state) =>
    getCategoryAnswers(state, id!),
  );
  const categoryDetailStatuses = useAppSelector(
    (state: RootState) => state.categories.categeoryDetailStatus,
  );

  useEffect(() => {
    dispatch(fetchCategoryById(id!));
  }, [categoryDetailStatuses, dispatch]);

  // const catDs = useAppSelector(
  //   (state: RootState) => state.categories.categoryDetails,
  // );

  const catD = useAppSelector((state: RootState) =>
    selectCategoryById(state, id!),
  );

  const handleBackClick = () => {
    const current = currentFlashcard - 1;
    if (current > 0) setCurrentFlashcard(current);
    dispatch(setFlipCard(false));
  };

  const handleForwardClick = () => {
    const current = currentFlashcard + 1;
    if (current < catD!.flashcards.length) setCurrentFlashcard(current);
    dispatch(setFlipCard(false));
  };

  const backgroundColor = cardFlipped ? "#F7941E" : "#262262";

  const hasAnswers = categoryAnswers !== undefined;

  if (id === undefined || categoryDetailStatuses[id] === "loading") {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else if (categoryDetailStatuses[id] === "succeeded") {
    const category = catD!;
    return (
      <>
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "87vh",
          }}
        >
          <Box
            sx={{
              mt: 2,
              textAlign: "center",
              backgroundColor: backgroundColor,
              pt: 5,
              pb: 2,
              color: "white",
              transition: "background-color 0.5s",
            }}
          >
            <Typography level="h4" textColor="common.white">
              Click arrows to move to next question. Click card to reveal
              answer.
            </Typography>
            {hasAnswers && (
              <Typography textColor="common.white">
                 
                <PDFDownloadLink
                  document={<OutputPDF />}
                  fileName="Flashcard Session Progress.pdf"
                >
                  {({ blob, url, loading, error }) => {
                    const text = loading ? "Loading" : "Download PDF";
                    return <Link textColor="common.white" underline="hover" >View</Link>;
                  }}
                </PDFDownloadLink> your recent progress
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              backgroundColor: backgroundColor,
              transition: "background-color 0.5s",
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "row",
              flexGrow: 1,
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: 2,
                }}
              >
                <IconButton size="lg" onClick={handleBackClick}>
                  <ArrowBack />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {catD!.flashcards.length > 0 && (
                  <Flashcard
                    flashcard={category.flashcards[currentFlashcard]}
                    categoryName={category.name}
                    categoryId={category.id}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ml: 2,
                }}
              >
                <IconButton size="lg" onClick={handleForwardClick}>
                  <ArrowForward />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
};
