import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "aws-amplify/api";
import { RootState } from "../store";

//API response type
export type ListCategoriesAPIResponse = {
  success: boolean;
  data: ListCategoriesResponse[];
};
export type ListCategoriesResponse = {
  id: string;
  name: string;
  description: string;
  totalFlascards: number;
};

export type GetCategoryAPIResponse = {
  success: boolean;
  data: GetCategoryResponse;
};

export type GetCategoryResponse = {
  id: string;
  name: string;
  description: string;
  totalFlascards: number;
  randomise: boolean;
  flashcards: Flashcard[];
};

export type Flashcard = {
  id: string;
  question: string;
  answer: string;
  title: string;
  subtitle: string;
  location: string;
  resource: string;
};

type CategoriesState = {
  categories: ListCategoriesResponse[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  categeoryDetailStatus: {
    [key: string]: "idle" | "loading" | "succeeded" | "failed" | undefined;
  };
  categoryDetails: GetCategoryResponse[];
};

const initialState: CategoriesState = {
  categories: [],
  status: "idle",
  error: null,
  categeoryDetailStatus: {},
  categoryDetails: [],
};

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    //api call to get categories
    const categories = get({
      apiName: "APIGateway",
      path: "user/categories",
      options: {},
    });
    const response = await categories.response;
    const json = (await response.body.json()) as ListCategoriesAPIResponse;
    return json.data;
  },
);

export const fetchCategoryById = createAsyncThunk(
  "categories/fetchCategoryById",
  async (id: string) => {
    const categories = get({
      apiName: "APIGateway",
      path: `user/categories/${id}`,
      options: {},
    });
    const response = await categories.response;
    const json = (await response.body.json()) as GetCategoryAPIResponse;
    return json.data;
  },
  {
    condition: (id, { getState }) => {
      const state = getState() as RootState;
      const fetchStatus = state.categories.categeoryDetailStatus[id];
      if (fetchStatus === "loading" || fetchStatus === "succeeded") {
        return false;
      }
    },
  },
);

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        //@ts-ignore
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(fetchCategoryById.pending, (state, action) => {
        console.log("fetchCategoryById.pending", action);
        state.categeoryDetailStatus[action.meta.arg] = "loading";
      })
      .addCase(fetchCategoryById.fulfilled, (state, action) => {
        console.log("fetchCategoryById.fulfilled", action);
        state.categeoryDetailStatus[action.payload!.id] = "succeeded";
        state.categoryDetails.push(action.payload);
      })
      .addCase(fetchCategoryById.rejected, (state, action) => {
        console.log("fetchCategoryById.rejected", action);
        state.categeoryDetailStatus[action.meta.arg] = "failed";
      });
  },
});

export default categoriesSlice.reducer;

export const selectAllCategories = (state: RootState) =>
  state.categories.categories;

export const selectCategoryById = (state: RootState, id: string) => {
  return state.categories.categoryDetails.find(
    (category: GetCategoryResponse) => category.id === id,
  );
};
