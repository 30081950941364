import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Sheet,
  Typography,
} from "@mui/joy";
import * as Yup from "yup";
import { useFormik } from "formik";
import { signIn } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

export const Login = () => {

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string().email().required("Please enter your email"),
    password: Yup.string().required("Please enter your password"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      login: "",
    },
    validationSchema: validationSchema,
    
    onSubmit: (values) => {
      setIsLoading(true);
      signIn({
        username: values.email,
        password: values.password,
      })
        .then(user => {console.log(user)
          setIsLoading(false);
          navigate("/");
        })
        .catch(err => {
          setIsLoading(false);
          console.log(err);
          formik.setErrors({login: "Invalid username or password"});
        }
        
        );
    },
  });

  const background = process.env.PUBLIC_URL + "/PDBackground.svg";
  return (
    <Box
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundColor: "#262262",
        backgroundImage: `url(${background})`,
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Sheet
        sx={{
          width: 450,
          mx: "auto", // margin left & right
          // my: 4, // margin top & bottom
          py: 3, // padding top & bottom
          px: 2, // padding left & right
          display: "flex",
          flexDirection: "column",
          gap: 2,
          // borderRadius: "sm",
          // boxShadow: "lg",
          // backgroundColor: "#262262",
          backgroundColor: "transparent",
          // borderColor: "#ffffff",
          borderColor: "transparent",
          // border: "2px solid",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/PDLogo.svg"}
                alt="logo"
                width={200}
                // height={250}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <Typography
                level="h4"
                justifyContent="center"
                textColor="common.white"
              >
                Principle Defence Flashcards
              </Typography>
            </Box>
            <FormControl>
              <FormLabel
                sx={{
                  color: "#ffffff",
                  alignSelf: "center",
                }}
              >
                Email
              </FormLabel>
              <Input
                // html input attribute
                name="email"
                type="email"
                placeholder="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              <FormHelperText 
                sx={{
                  color: "#ffffff",
                  alignSelf: "center",
                }}
              >
                {formik.touched.email && formik.errors.email}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel
                sx={{
                  color: "#ffffff",
                  alignSelf: "center",
                }}
              >
                Password
              </FormLabel>
              <Input
                name="password"
                type="password"
                placeholder="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
              />
              <FormHelperText  sx={{
                  color: "#ffffff",
                  alignSelf: "center",
                }}>
                {formik.touched.password && formik.errors.password}
              </FormHelperText>
            </FormControl>
            <Button type="submit" size="lg" loading={isLoading}>
              LOG IN
            </Button>
            <FormHelperText>
              {formik.touched.login && formik.errors.login}
            </FormHelperText>
          </Box>
        </form>
      </Sheet>
    </Box>
    
  );
};
