import { configureStore } from "@reduxjs/toolkit";
import cardReducer from "./features/card";
import answersReducer from "./features/answers";
import categoriesReducer from "./features/categories";
// import progressReducer from "./features/progress";

export const store =  configureStore({
    reducer: {
      card: cardReducer,
      answers: answersReducer,
      categories: categoriesReducer,
    }
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
