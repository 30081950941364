import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getCurrentUser } from "aws-amplify/auth";
//@ts-ignore
export const ProtectedRoute = ({ children }) => {
  const checkAuthentication = async () => {
    try {
      await getCurrentUser();
      setIsAuth(true);
    } catch {
      setIsAuth(false);
    }
  };

  const [isAuth, setIsAuth] = useState<boolean>();

  useEffect(() => {
    console.log("checking auth");
    checkAuthentication()

  }, []);

  if (isAuth === undefined) {
    return <div>loading</div>; // or loading spinner/indicator/etc
  }

  return isAuth ? children : <Navigate to="/login" replace />;
};
