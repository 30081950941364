import React, { useEffect } from "react";
import {
  selectAllCategories,
  fetchCategories,
  ListCategoriesResponse,
} from "../../redux/features/categories";
import { RootState } from "../../redux/store";
import { Category } from "./Category";
import { Box, CircularProgress, Grid } from "@mui/joy";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

export const ListCategories = () => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(selectAllCategories);

  const categoriesStatus = useAppSelector(
    (state: RootState) => state.categories.status,
  );
  const error = useAppSelector((state: RootState) => state.categories.error);

  useEffect(() => {
    if (categoriesStatus === "idle") {
      dispatch(fetchCategories());
    }
  }, [categoriesStatus, dispatch]);

  if (categoriesStatus === "loading") {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else if (categoriesStatus === "succeeded") {
    return (
      <Grid container spacing={2}>
        {categories.map((category: ListCategoriesResponse, index: number) => (
          <Category
            key={category.id + index}
            id={category.id}
            name={category.name}
            index={index}
          />
        ))}
      </Grid>
    );
  } else {
    return <Box>Error: {error}</Box>;
  }
};
