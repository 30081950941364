import { Typography } from "@mui/joy";
import { DOMNode, Element } from "html-react-parser";

const replaceSuperString = (domNode: DOMNode) => {
  if ((domNode as Element).name === "sup") {
    return (
      <Typography
        sx={{
          fontSize: "0.5em",
          verticalAlign: "super" as const,
          marginTop: "-0.5em",
          color: "#ffffff",
        }}
      >
        TM
      </Typography>
    );
  } else {
    return domNode;
  }
};

export { replaceSuperString };
