import { Amplify } from "aws-amplify";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Login } from "./routes/Login";
import { CssVarsProvider, extendTheme } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";

import { Authenticator } from "@aws-amplify/ui-react";
import { Home } from "./routes/Home";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Flashcards } from "./routes/Flashcards";
import { fetchAuthSession } from "aws-amplify/auth";
import { Provider } from "react-redux";
import { store } from "./redux/store";

Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
        userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
      },
    },
    API: {
      REST: {
        APIGateway: {
          endpoint: process.env.REACT_APP_API_GATEWAY_URL + "/",
        },
        // // Endpoint printed in CDK deploy output or found in AWS
        // // Management Console
        // endpoint: process.env.REACT_APP_API_GATEWAY_URL +"/",
        // custom_header: async () => {
        //   return {
        //     Authorization: `Bearer ${(await Auth.currentSession())
        //       .getIdToken()
        //       .getJwtToken()}`,
        //   };
        // },
      },
    },
  },
  {
    API: {
      REST: {
        headers: async () => ({
          Authorization: `Bearer ${(await fetchAuthSession()).tokens?.idToken?.toString()}`,
        }),
      },
    },
  },
);

const pdTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          solidBg: "#F7941E",
          solidBorder: "#F7941E",
          solidHoverBg: "rgba(247, 149, 30)",
          solidHoverBorder: "rgba(247, 149, 30)",
          solidActiveBg: "#F7941E",
          solidActiveBorder: "#F7941E",
          solidDisabledBg: "#F7941E",
          solidDisabledBorder: "#F7941E",
        },
        warning: {
          solidBg: "#262262",
          solidBorder: "#262262",
          solidHoverBg: "#262262",
          solidHoverBorder: "#262262",
          solidActiveBg: "#262262",
          solidActiveBorder: "#262262",
          solidDisabledBg: "#262262",
          solidDisabledBorder: "#262262",
        },
      },
    },
  },
});

const App = () => (
  <CssVarsProvider
    defaultMode="light"
    disableTransitionOnChange
    theme={pdTheme}
  >
    <CssBaseline />

    <HashRouter>
      <Provider store={store}>
        <Authenticator.Provider>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/flashcards/:id"
              element={
                <ProtectedRoute>
                  <Flashcards />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Authenticator.Provider>
      </Provider>
    </HashRouter>
  </CssVarsProvider>
);

export default App;
