import { Fragment } from "react";
import { Header } from "../components/Header";
import { Box, Typography } from "@mui/joy";
import { ListCategories } from "../components/category/ListCategories";

export const Home = () => {
  return (
    <Fragment>
      <Header />
      <Box
        maxWidth="lg"
        sx={{
          mx: "auto",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography>
            Hello and welcome to the Principle Defence flashcard application.
            We’ve developed this resource to help you study for your exam(s).
            The flashcards cover the key concepts, terms and processes related
            to your course of learning. They shouldn’t be your only source of
            revision, but they are a helpful technique for memorising
            vocabulary, definitions and core facts. We hope you find it useful
            as an accompaniment to the training you have done with us. Good luck
            with your exam preparation!
          </Typography>
          <Typography
            level="h4"
            sx={{
              mt: 1,
              mb: 1,
            }}
            textColor="#F7941E"
          >
            Instructions for use:
          </Typography>
          <Typography>
            Click on the relevant icon below for the qualification for which you
            are revising. Each flashcard poses a question related to your course
            of learning: try to answer it without checking. To confirm the
            correct response, click the card to reveal the answer, alongside
            references to where you can find more information on the question.
            Included on the answer card is the option to confirm whether you got
            the answer right or not. You don’t have to use this feature, but if
            you do so, the app will log this and – once you have completed the
            flashcards - advise you on areas on which you might need to do some
            further revision. This results matrix can be downloaded in pdf form.
            To move onto the next question, or back to check a previous one, use
            the arrows on either side of the card to bring up further questions.
          </Typography>
          <Typography
            level="h4"
            sx={{
              mt: 1,
              mb: 1,
            }}
            textColor="#F7941E"
          >
            Questions / Issues with your account:
          </Typography>
          <Typography>
            The cards are based on the latest course material and will be
            updated when things change. You should have access to the flashcards
            related to the training courses you’ve taken with us. if you have
            any issues please contact us at:
            principledefence@principledefence.com. We also welcome any feedback
            on the usefulness of the app and how to maximise its potential to
            you.
          </Typography>
          <Typography
            level="h4"
            sx={{
              mt: 1,
              mb: 1,
            }}
            textColor="#F7941E"
          >
            Community:
          </Typography>
          <Typography>
            If you want to connect, study and help with other learners, you can
            join the Principle Defence Discord server{" "}
            <a href="https://discord.gg/StyvP8n6bh" target="_blank">
              here
            </a>
          </Typography>
        </Box>
        <ListCategories />
      </Box>
    </Fragment>
  );
};
