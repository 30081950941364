import React, { MouseEvent } from "react";
import "./Flashcard.css";
import { Box, Button, ButtonGroup, Tooltip, Typography } from "@mui/joy";
import parse from "html-react-parser";
import { replaceSuperString } from "../../lib/htmlParse";
import { useDispatch } from "react-redux";
import { flipCard, getIsFlipped } from "../../redux/features/card";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  TaskAlt as Correct,
  CancelOutlined as Incorrect,
} from "@mui/icons-material";
import { getCategoryAnswers, getFlashcardAnswers, setFlashcardAnswer, submitAnswers } from "../../redux/features/answers";
import { Flashcard as FCType } from "../../redux/features/categories";
type FlashcardProps = {
  categoryId: string;
  categoryName: string;
  flashcard: FCType;
};

export const Flashcard = (props: FlashcardProps) => {
  const { categoryName, categoryId } = props;
  const { id, question, answer, resource, location } = props.flashcard;

  const dispatch = useAppDispatch();

  const fcans = useAppSelector((state) => getFlashcardAnswers(state, categoryId, id));

  const flashcardAnswered = fcans ? true : false;
  const flashcardAnswerCorrect = fcans ? fcans.correct : false;

  // console.log("fcans");
  // console.log(fcans);
  const side = useAppSelector(getIsFlipped);

  const handleClick = () => {
    dispatch(flipCard());
  };

  const handleYesClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    console.log("Yes - ", id, categoryId);
    dispatch(
      setFlashcardAnswer({
        categoryId: categoryId,
        questionAnswers: [
          {
            flashcardId: id,
            correct: true,
          },
        ],
      }),
    );
    dispatch(submitAnswers({ categoryId, answers: [{ flashcardId: id, correct: true }] }));
  };

  const handleNoClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    console.log("No - ", id);
    dispatch(
      setFlashcardAnswer({
        categoryId: categoryId,
        questionAnswers: [
          {
            flashcardId: id,
            correct: false,
          },
        ],
      }),
    );
    dispatch(submitAnswers({ categoryId, answers: [{ flashcardId: id, correct: false }] }));
  };
  return (
    <div className={`card ${side ? "side" : ""}`} onClick={handleClick}>
      <small>
        <span>
          {parse(categoryName, {
            replace: (domNode) => {
              replaceSuperString(domNode);
            },
          })}
        </span>
      </small>
      <div className="front">
        <span className="question">Question:</span>
        <span className="question-text">{question}</span>
        {flashcardAnswered && (
          <span className="answered">
            {flashcardAnswerCorrect ? (
              <Tooltip
                title="You have previously answered this question correctly"
                arrow
                placement="top-end"
              >
                <Correct />
              </Tooltip>
            ) : (
              <Tooltip title="Yout have prevously answered this question incorrectly">
                <Incorrect />
              </Tooltip>
            )}
          </span>
        )}
      </div>
      <div className="back">
        <span className="answer-text">{parse(answer)}</span>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderTop: "1px solid #262262",
            borderBottom: "1px solid #262262",
            pt: 2,
            pb: 2,
            mt: 3,
            width: "400px",
          }}
        >
          <Typography
            textColor="common.black"
            sx={{
              pb: 1,
            }}
          >
            Did you get the answer right?
          </Typography>
          <ButtonGroup variant="solid" size="lg">
            <Button color="warning" onClick={handleYesClick}>
              Yes
            </Button>
            <Button color="danger" onClick={handleNoClick}>
              No
            </Button>
          </ButtonGroup>
        </Box>
        <div className="resource">
          <Typography
            textColor="common.black"
            sx={{
              pt: 1,
              pb: 1,
            }}
          >
            {location} - {resource}
          </Typography>
        </div>
      </div>
    </div>
  );
};
