import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface CardState {
  isFlipped: boolean;
}

export const initialState: CardState = {
  isFlipped: false,
};

export const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    /**
     * Changes the state of the card to be the opposite of what it currently is
     * @param state current state of the redux store
     * @returns The new state of the card
     */
    flipCard: (state) => {
      state.isFlipped = !state.isFlipped;
    },
    /**
     * Sets the state of the card to be the value passed in 
     * @param state current state of the redux store
     * @param action true or false
     * @returns The new state of the card
     */
    setFlipCard: (state, action: PayloadAction<boolean>) => {
      state.isFlipped = action.payload;
    }
  },
});

/**
 * get the current state of the card, if it is flipped or not
 * @param state current state of the redux store
 * @returns The state of the card
 */ 
export const getIsFlipped = (state: RootState) => state.card.isFlipped;

export const { flipCard , setFlipCard} = cardSlice.actions;
export default cardSlice.reducer;
