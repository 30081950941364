import { AspectRatio, Card, Grid, Typography } from "@mui/joy";
import { Link } from "react-router-dom";
import parse, { Element } from "html-react-parser";

export type CategoryProps = {
  id: string;
  name: string;
  index: number;
};

export const Category = (props: CategoryProps) => {
  const { id, name, index } = props;

  const calculateBackground = (index: number): string => {
    if (index % 3 === 0) {
      return "#262262";
    } else if (index % 2 === 0) {
      return "#F7941E";
    } else {
      return "#ED1C24";
    }
  };

  return (
    <Grid sm={6} xs={12} md={4}>
      <Link to={`/flashcards/${id}`}>
        <AspectRatio>
          <Card
            key={id}
            sx={{
              cursor: "pointer",
              backgroundColor: calculateBackground(index),
              "&::before": {
                content: '""',
                position: "absolute",
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/PDLogo.svg"
                })`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "30%",
                opacity: 0.25,
              },
            }}
          >
            <Typography level="h3" textColor="#ffffff">
              {parse(name, {
                replace: (domNode) => {
                  if ((domNode as Element).name === "sup") {
                    return (
                      <Typography
                        sx={{
                          fontSize: "0.5em",
                          verticalAlign: "super",
                          mt: "-0.5em",
                          color: "#ffffff",
                        }}
                      >
                        TM
                      </Typography>
                    );
                  }
                },
              })}
            </Typography>
          </Card>
        </AspectRatio>
      </Link>
    </Grid>
  );
};
