import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import _ from "underscore";
import { post } from "aws-amplify/api";

export interface AnswersState {
  categoryAnswers: Array<CategoryAnswersAction>;
}
export interface FlashcardAnswersAction {
  flashcardId: string;
  correct?: boolean;
}
export interface CategoryAnswersAction {
  categoryId: string;
  questionAnswers: Array<FlashcardAnswersAction>;
}

export const initialState: AnswersState = {
  categoryAnswers: new Array<CategoryAnswersAction>(),
};

export const submitAnswers = createAsyncThunk(
  "user/submitAnswers",
  async ({
    categoryId,
    answers,
  }: {
    categoryId: string;
    answers: [{ flashcardId: string; correct: boolean }];
  }) => {
    //api call to get categories
    const categories = post({
      apiName: "APIGateway",
      path: "user/answers/" + categoryId,
      options: {
        body: {
          answers: answers,
        },
      },
    });
    const response = await categories.response;
  },
);

export const answerSlice = createSlice({
  name: "answers",
  initialState,
  reducers: {
    // flipCard: (state) => {
    //   state.isFlipped = !state.isFlipped;
    // },
    // setFlipCard: (state, action: PayloadAction<boolean>) => {
    //   state.isFlipped = action.payload;
    // }
    // setCurrentSection: (state, action: PayloadAction<number>) => {
    //   state.currentSection = action.payload;
    // },
    // nextSection: (state) => {
    //   const newSection = state.currentSection + 1;
    //   if (newSection <= state.totalSections) {
    //     state.currentSection = newSection;
    //   }
    // },
    // prevSection: (state) => {
    //   if (state.currentSection > 0) {
    //     state.currentSection = state.currentSection - 1;
    //   }
    // },
    // setTotalSections: (state, action: PayloadAction<number>) => {
    //   state.totalSections = action.payload - 1;
    // },
    // setSections: (state, action: PayloadAction<[]>) => {
    //   state.sections = action.payload;
    //   state.totalSections = action.payload.length - 1;
    // },
    setFlashcardAnswer: (
      state,
      action: PayloadAction<CategoryAnswersAction>,
    ) => {
      console.log("setCategoryFlashcardAnswer");
      console.log(action.payload);
      console.log(state);
      const categoryIndex = state.categoryAnswers.findIndex(
        (ca: CategoryAnswersAction) => {
          return ca.categoryId === action.payload.categoryId;
        },
      );
      console.log("categoryIndex Found: " + categoryIndex);
      if (categoryIndex > -1) {
        const p = state.categoryAnswers[categoryIndex];
        console.log(p);
        state.categoryAnswers[categoryIndex].questionAnswers = _.union(
          action.payload.questionAnswers,
          state.categoryAnswers[categoryIndex].questionAnswers,
        );
      } else {
        //create new
        console.log("create new");
        state.categoryAnswers.push({
          categoryId: action.payload.categoryId,
          questionAnswers: action.payload.questionAnswers,
        } as CategoryAnswersAction);
      }

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitAnswers.pending, (state) => {
        console.log("submitAnswers.pending");
      })
      .addCase(submitAnswers.fulfilled, (state, action) => {
        console.log("submitAnswers.fulfilled");
      })
      .addCase(submitAnswers.rejected, (state, action) => {
        console.log("submitAnswers.rejected");
      });
  },
});

export const getIsFlipped = (state: RootState) => state.card.isFlipped;

export const { setFlashcardAnswer } = answerSlice.actions;
export default answerSlice.reducer;

export const getCategoryAnswers = createSelector(
  [
    (state: RootState) => state.answers.categoryAnswers,
    (state: RootState, categoryId: string) => categoryId,
  ],
  (state: any, categoryId: string) => {
  console.log("getCategoryAnswers", state, categoryId);
    return state.find((ca: CategoryAnswersAction) => {
      return ca.categoryId === categoryId;
    });
  });

export const getFlashcardAnswers = createSelector(
  [
    (state: RootState) => state.answers.categoryAnswers,
    (state: RootState, categoryId: string, flashcardId: string) => {
      return { categoryId, flashcardId };
    },
  ],
  (state: any, { categoryId, flashcardId }: { categoryId: string; flashcardId: string }) => {
    console.log("getFlashcardAnswers", state, categoryId, flashcardId);
    const category = state.find((ca: CategoryAnswersAction) => {
      return ca.categoryId === categoryId;
    });
    if (category) {
      return category.questionAnswers.find((qa: FlashcardAnswersAction) => {
        return qa.flashcardId === flashcardId;
      });
    }
  });
