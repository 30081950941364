import { Box, Grid, Typography } from "@mui/joy";
import Link from "@mui/joy/Link";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import { signOut, getCurrentUser, fetchUserAttributes } from "aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
export const Header = () => {
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");

  const handleLogout = async () => {
    await signOut();
    setUserName("");
    navigate("/login");
  };

  const getUserName = async () => {
    const user = await fetchUserAttributes();
    return user.name || "";
  };

  const p = useAuthenticator();
  console.log(p);


  useEffect(() => {
    getUserName().then((u) => setUserName(u));
  }, []);

  return (
    <Box sx={{}}>
      <Box
        sx={{
          display: "flex",
          direction: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Link
          onClick={handleLogout}
          sx={{
            mt: 2,
            mr: 2,
          }}
        >
          <Typography startDecorator={<Logout />}>Logout</Typography>
        </Link>
      </Box>

      <Grid container>
        <Grid
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            alignItems: "center",
            pl: 2,
          }}
        >
          <Typography level="h4" textColor="#262262">
            {" "}
            <RouterLink
              to="/"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Welcome {userName}
            </RouterLink>
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <RouterLink to="/">
            <img
              src={process.env.PUBLIC_URL + "/PDLogoColour.svg"}
              alt="logo"
              width={80}
            />
          </RouterLink>
        </Grid>
      </Grid>
    </Box>
  );
};
